import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import UniversalGridder from '../../styles/universal-gridder';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageBody,
  InpageSuptitle
} from '../../styles/inpage';
import TopicsNav from '../../components/topics-nav';
import PostsList from './posts-list';

import { useBlogData } from './reducer-actions';
import DataLoadButton from './data-load-button';

const Posts = styled(UniversalGridder).attrs({
  as: 'section',
  grid: {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
})`
  grid-column: 1 / -1;
  background: transparent;
`;

export default function BlogTopic({ pageContext, location }) {
  // Get the static values form the page context.
  // In this case the initial posts to render, the list of topics.
  const { posts, topics, currentTopic } = pageContext;

  const [page, setPage] = useState(1);

  const [state, request] = useBlogData(posts);

  // Request new data on filters change
  useEffect(() => {
    request(currentTopic.id, page);
  }, [currentTopic, page, request]);

  // Request new data on filters change

  // Static data comes from the static render, and is not loaded asynchronously.
  // This is the default state, with no filters and page 1.
  const isStaticData = page === 1;

  const postData = isStaticData ? posts : state.data;

  const hasError = !isStaticData && !!state.error;
  const isLoadingData = !isStaticData && state.fetchingData;
  const isLoadingPage = !isStaticData && state.fetchingPage;

  const hasMorePosts = postData
    ? postData.meta.total > postData.results.length
    : false;

  return (
    <Layout
      location={location}
      title={`Blog - ${currentTopic.label}`}
      description={`View blog posts under ${currentTopic.label}`}
    >
      <Inpage as='section'>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>{currentTopic.label}</InpageTitle>
              <InpageSuptitle>
                <Link to='/blog' title='View Blog page'>
                  Topic
                </Link>
              </InpageSuptitle>
            </InpageHeadline>

            <TopicsNav topics={topics} current={currentTopic} base='/blog' />
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <Posts>
            <PostsList
              hasError={hasError}
              isLoadingData={isLoadingData}
              isLoadingPage={isLoadingPage}
              data={postData}
            />

            {!hasError && !!postData?.results.length && (
              <DataLoadButton
                count={postData.results.length}
                total={postData.meta.total}
                hasMore={hasMorePosts}
                isLoading={isLoadingPage}
                onClick={() => setPage((p) => p + 1)}
              />
            )}
          </Posts>
        </InpageBody>
      </Inpage>
    </Layout>
  );
}

BlogTopic.propTypes = {
  pageContext: T.shape({
    currentTopic: T.object,
    posts: T.object,
    topics: T.array
  }),
  location: T.object
};
